import React from 'react';

const Project = ({ project }) => {
  const githubIconUrl = "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png";

  return (
    <div className="project-card">
      <h3>{project.name}</h3>
      <p>{project.description}</p>
      <ul className="project-languages">
        {project.languages.map(lang => (
          <li key={lang}>{lang}</li>
        ))}
      </ul>
      {project.repoUrl && (
        <a href={project.repoUrl} target="_blank" rel="noopener noreferrer" className="github-icon">
          <img src={githubIconUrl} alt="GitHub" />
        </a>
      )}
    </div>
  );
};

export default Project;
