import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './css/App.css';
import Header from './Header';
import Projects from './Projects';
import Resume from './Resume';
import Contact from './Contact';
import profilePic from './images/image.png';

function Home() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Setting timeout to simulate asynchronous loading, you can remove this if not needed
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100); // You can adjust this delay
    return () => clearTimeout(timer);
  }, []);

  const animationClass = isLoaded ? 'fadeInUp' : '';

  return (
    <div className={`home-container ${animationClass}`}>
      <div className={`home-content ${animationClass}`}>
        <img src={profilePic} alt="Profile" className={`profile-pic ${animationClass}`} />
        <div className={`intro-text ${animationClass}`}>
          <h1>Hello, I'm Hamaad</h1>
          <p>I graduated from Sheridan College with a degree in Software Development & Network Engineering. I plan to continue my education at York University. Welcome to my website!</p>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
