import React from 'react';
import './css/Resume.css';

function Resume() {
  return (
    <div className="resume-container">
      <div className="resume-body">
        <section className="experience-section">
          <h2>Experience</h2>
          <h3>Tutor - GeniusCamp</h3>
          <p>November 2022 - April 2024</p>
          <ul>
            <li>Provided personalized tutoring and tailored instruction for individual students</li>
            <li>Delivered engaging classroom lessons and managed classroom dynamics effectively</li>
            <li>Developed customized lesson plans and materials for diverse learning styles</li>
            <li>Assessed student progress and provided constructive feedback</li>
          </ul>
        </section>

        <div className="right-column">
        <section className="skills-section">
          <h2>Skills</h2>
          <div className="skills-list-container">
          <ul className="skills-list">
            <li>Python</li>
            <li>JS/React</li>
            <li>C#</li>
            <li>SQL</li>
            <li>Version Control</li>
          </ul>
          <ul className="skills-list">
            <li>Java</li>
            <li>Machine Learning</li>
            <li>Data Analysis</li>
            <li>Software Proficiency</li>
            <li>Problem-Solving</li>
          </ul>
          </div>
        </section>

          <section className="education-section">
            <h2>Education</h2>
            <h3>Degree - York University</h3>
            <p>Sept 2024 - April 2026</p>
            <h3>Adv. Diploma - Sheridan College</h3>
            <p>Sept 2021 - April 2024</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Resume;