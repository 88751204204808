import React, { useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import emailjs from 'emailjs-com';
import loadingGif from './images/loading.gif';
import checkmarkImage from './images/checkmark.png';
import './css/Contact.css';

function Contact() {
  const form = useRef();
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    message: ''
  });
  const [submitting, setSubmitting] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const position = [43.5930, -79.6425];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const isFormValid = () => {
    return formData.from_name && formData.from_email && formData.message;
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    setSubmitting(true);
    setCurrentImage(loadingGif);

    emailjs.sendForm('service_t7ebkak', 'template_o01ycou', form.current, 'HVIDanrsKPUsYgKJB')
      .then((result) => {
          console.log(result.text);
          setCurrentImage(checkmarkImage); 
          setFormData({ 
            from_name: '',
            from_email: '',
            message: ''
          }); 
      }, (error) => {
          console.log(error.text);
          setCurrentImage(null); 
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="contact-container">
      <div className="contact-form">
        <h2>Contact Me</h2>

        {currentImage && <img src={currentImage} alt="Submission Status" className="submission-status-image" />}
        
        {!submitting && (
          <form ref={form} onSubmit={sendEmail}>
            <input 
              type="text" 
              name="from_name" 
              placeholder="Name" 
              required 
              value={formData.from_name} 
              onChange={handleChange}
            />
            <input 
              type="email" 
              name="from_email" 
              placeholder="Email" 
              required 
              value={formData.from_email} 
              onChange={handleChange}
            />
            <textarea 
              name="message" 
              placeholder="Message" 
              required 
              value={formData.message} 
              onChange={handleChange}
            ></textarea>
            <button type="submit" disabled={!isFormValid()}>Send</button>
          </form>
        )}
      </div>

      <MapContainer center={position} zoom={10} scrollWheelZoom={false} className="map">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> '
        />
      </MapContainer>
    </div>
  );
}

export default Contact;